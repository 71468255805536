import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PathConstants } from '@core/constants/url-constants';
import { UserInformationService } from '@core/services/user-information.service';

/**
 * Guard um die Benutzerrechte zu prüfen und eine Navigation auf Unterseiten zu ermöglichen/verhindern
 * @param userRights Benutzerrechte (oder-verknüpft)
 * @returns CanActivateFn
 */
export function userRightsGuard(userRights: string[]): CanActivateFn {
    return (): boolean => {
        const service = inject(UserInformationService);
        const router = inject(Router);
        // Überprüft, ob der Benutzer eines der mitgegebenen Rechte besitzt
        const hasRights = service.hasPermissions(userRights);
        // Hat der Benutzer keines dieser Rechte, wird er auf Home weitergeleitet
        if (!hasRights) {
            router.navigate([PathConstants.HOME]);
        }
        return hasRights;
    };
}
